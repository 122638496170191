var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "boxed-layout" }, [
    _c("h2", { staticClass: "font-weight-bold my-3" }, [_vm._v("Home")]),
    _vm._v(" "),
    _c("p", { staticClass: "text-muted" }, [
      _vm._v("Hallo " + _vm._s(_setup.account.login)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-md-4" }, [
        _c("div", { staticClass: "card border-0 shadow-sm" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h5", [_vm._v("Einstellungen")]),
            _vm._v(" "),
            _c("p", { staticClass: "text-muted" }, [
              _vm._v("Alle Versicherungstechnischen Einstellungen"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-right" },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "PercentageSettings" } } },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                      },
                      [
                        _vm._v(
                          "\n                Einstellungen\n                "
                        ),
                        _c("font-awesome-icon", {
                          attrs: { icon: "chevron-right" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-4" }, [
        _c("div", { staticClass: "card border-0 shadow-sm card-body" }, [
          _c("h5", [_vm._v("Alle Berichte")]),
          _vm._v(" "),
          _c("p", { staticClass: "text-muted" }, [
            _vm._v("Bestehende Berichte einsehen"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-right" },
            [
              _c("router-link", { attrs: { to: { name: "Reports" } } }, [
                _c(
                  "button",
                  {
                    staticClass: "ml-auto btn btn-primary",
                    attrs: { type: "button" },
                  },
                  [
                    _vm._v("\n              Zu den Berichten\n              "),
                    _c("font-awesome-icon", {
                      attrs: { icon: "chevron-right" },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-4" }, [
        _c("div", { staticClass: "card border-0 shadow-sm card-body" }, [
          _c("h5", [_vm._v("Bericht")]),
          _vm._v(" "),
          _c("p", { staticClass: "text-muted" }, [
            _vm._v("Jetzt einen neuen Bericht erstellen."),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-right" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "ReportCustomerSelection" } } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "ml-auto btn btn-primary",
                      attrs: { type: "button" },
                    },
                    [
                      _vm._v("\n              Neu\n              "),
                      _c("font-awesome-icon", { attrs: { icon: "add" } }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }